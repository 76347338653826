<template>
  <div class="cpt-mount cpt-MMC_Gimbal_FE8">
    <div class="bd">
      <div class="form-wrap mount-form">
        <div class="form-item">
          <div class="label-box">保&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;险</div>
          <div class="input-box">
            <el-switch v-model="safety_switch_state" size="mini"></el-switch>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">发&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;射</div>
          <div class="input-box">
            <el-switch
              @change="handle_laser_shine_ctrl"
              v-model="laser_shine_status"
              size="mini"
            ></el-switch>
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="title-box">
          <div class="title">操&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;作</div>
        </div>
        <div class="rocker-box">
          <div class="rocker" ref="rocker"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nipplejs from "nipplejs";
import { MMC_Gimbal_FE8 } from "../utils";
export default {
  props: {
    whachChange:{
      typeof:Number,
      default:0
    }
  },
  data() {
    return {
      radio: 1,
      safety_switch_state: false,
      laser_shine_status: false,
      joystick:null
    };
  },
  methods: {
    handle_laser_shine_ctrl(value) {
      if (!this.safety_switch_state) {
        return (this.laser_shine_status = false);
      }
      let buff = MMC_Gimbal_FE8.laser_shine_ctrl(1);
      this.commit_directive(buff);
    },
    commit_directive(buffer) {
      // console.log(buffer);
      this.$emit("directive", buffer);
    },
    init_rocker() {
      this.joystick = nipplejs.create({
        zone: this.$refs["rocker"],
        mode: "static",
        position: {
          left: "50%",
          top: "50%",
        },
        color: "#9bacbe",
      });

      this.joystick
        .on("start", this.start_timer)
        .on("end", this.stop_timer)
        .on(
          "dir:up plain:up dir:left plain:left dir:down plain:down dir:right plain:right",
          (evt, data) => {
            const dir = data.direction.angle;
            switch (dir) {
              case "up":
                this.buffer = MMC_Gimbal_FE8.gimbal_pitch_ctrl(
                  1,
                  this.control_speed
                );
                break;
              // case "right":
              //   this.buffer = MMC_Gimbal_FE8.gimbal_yaw_ctrl(
              //     1,
              //     this.control_speed
              //   );
              //   break;
              // case "left":
              //   this.buffer = MMC_Gimbal_FE8.gimbal_yaw_ctrl(
              //     -1,
              //     this.control_speed
              //   );
              //   break;
              case "down":
                this.buffer = MMC_Gimbal_FE8.gimbal_pitch_ctrl(
                  -1,
                  this.control_speed
                );
            }
          }
        );
    },
    start_timer() {
      this.buffer = null;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.buffer) {
            this.commit_directive(this.buffer);
          }
        }, 200);
      }
    },
    stop_timer() {
      this.buffer = MMC_Gimbal_FE8.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(this.buffer);

      // this.buffer = MMC_Gimbal_FE8.gimbal_yaw_ctrl(0, this.control_speed);
      // this.commit_directive(this.buffer);

      if (this.buffer) {
        this.buffer = null;
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  watch:{
    whachChange(){
      if(this.joystick){
        this.joystick.destroy()
      }
      this.init_rocker()
    }
  },
  mounted() {
    this.$emit("width", '416px')
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
  beforeDestroy() {
    this.stop_timer();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/index.scss";
</style>